import { TagType } from "@/helpers/types";
import { FunctionComponent } from "react";
import Link from "next/link";
import mattersNum from "@/helpers/mattersNum";
import { date } from "@/helpers/date";

const DesktopStory: FunctionComponent<{ item: TagType }> = ({ item }) => {
  return (
    <div className="mx-auto w-[820px] text-white mt-[47px]">
      <div className="text-center text-lg mb-[8px]">Сюжет</div>
      <Link href={item.path}>
        <a className="text-center block text-white font-serif mb-[25px] text-[48px] leading-tight hover:text-white hover:underline">
          {item.title}
        </a>
      </Link>
      {item.matters && (
        <>
          <div className="text-center opacity-30 mt-[15px] mb-[70px]">
            <Link href={item.path}>
              <a className="inline-block leading-none px-[8px] py-[6px] border rounded-[8px] text-white hover:text-white">
                {item.matter_count} {mattersNum(item.matter_count)}
              </a>
            </Link>
          </div>
          <div className="grid grid-cols-3 gap-[29px]">
            {item.matters
              .filter((el, i) => i < 3)
              .map((matter, i) => (
                <Link href={matter.path} key={i}>
                  <a className="text-white pb-[50px] border-b border-[#ffffff30] hover:text-white">
                    <div className="text-lg leading-[1.44] mb-[20px]">
                      {matter.title}
                    </div>
                    <div className="text-[16px] opacity-30">
                      {date(
                        matter.published_at ||
                          matter.published_at_in_timezone.iso
                      )}
                    </div>
                  </a>
                </Link>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DesktopStory;
