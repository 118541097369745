export default function mattersNum(num: number): string {
  if (num > 10 && num < 20) return "материалов";

  switch (num % 10) {
    case 1:
      return "материал";
    case 2:
    case 3:
    case 4:
      return "материала";
    default:
      return "материалов";
  }
}
